import LendSide from "./LendSide";
import { Outlet } from "react-router-dom";
import { Modal, ModalContent, ModalBody, Drawer } from "@chakra-ui/react";
import Connect from "../Connect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";
import {
  useDisconnect,
  useWeb3Modal,
  useWeb3ModalProvider,
} from "@web3modal/ethers5/react";
import { chains } from "../../utils/chains";
import {
  BENQI_ADDRESS,
  devLog,
  formatter,
  truncateLarge,
} from "../../utils/helpers";
import ERC20ABI from "../../abis/erc20-classic.json";
import { ethers, Wallet } from "ethers";

import { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

const LendLayout = ({
  theme,
  setTheme,
  onClose,
  connectWallet,
  setConnectWallet,
  showSide,
  setShowSide,
  connected,
  addy,
  breakdown,
  setBreakdown,
  showDetails,
  setShowDetails,
  setConnected,
  chainId,
  action,
  isLoading,
}) => {
  const { disconnect } = useDisconnect();
  const { open } = useWeb3Modal();
  const [benqiBalance, setBenqiBalance] = useState(0);
  const { walletProvider } = useWeb3ModalProvider();

  useEffect(() => {
    if (!addy) return;
    async function setQIBalance() {
      if (chainId !== 43114) return;
      try {
        let balance;
        //const provider = new ethers.providers.JsonRpcProvider(chains[chainId].rpcUrl);
        const provider = new ethers.providers.Web3Provider(walletProvider);
        //const provider = new ethers.providers.JsonRpcProvider('https://rpc.ankr.com/avalanche/');
        const tokenContract = new ethers.Contract(
          BENQI_ADDRESS,
          ERC20ABI,
          provider
        );
        const decimals = await tokenContract.decimals();
        const preBalance = await tokenContract.balanceOf(addy);
        devLog("BENQI pre balance:", preBalance);
        balance = Number(preBalance) / 10 ** decimals;
        devLog("BENQI balance:", balance);
        setBenqiBalance(balance);
      } catch (error) {
        devLog(error + "ATTTT SET QI BALANCE");
      }
    }
    setQIBalance();
  }, [connected]);

  const navigate = useNavigate();

  useEffect(() => {
    const request = async () => {
      if (connected) {
        navigate("/verify");
      }
    };

    request();
  }, [connected]);

  return (
    <body className="flex flex-col lg:flex-row items-start w-[100%]">
      <div className="lg:hidden flex flex-row items-center justify-between w-[100%] p-[20px] dark:bg-[#161616] bg-[#d7edfd]">
        <img
          src={theme === "dark" ? "./images/li2.svg" : "./images/li.svg"}
          className="w-[44px]"
        />
        {!connected && (
          <button
            className="lg:bg-white lg:dark:bg-[#000000] border-solid border-[1px] border-[#00b3ed] text-black lg:dark:text-white
         text-[16px] font-[500] w-[148px] lg:w-[100%] lg:hover:bg-[#00b3ed] bg-[#00b3ed]
        rounded-[.375rem] py-[5px] flex flex-row items-center justify-center hover:dark:text-white"
            onClick={() => {
              //setConnectWallet(true);
              open();
            }}
          >
            Connect Wallet
          </button>
        )}

        <i
          className="bx bx-menu text-[40px] dark:text-white cursor-pointer"
          onClick={() => {
            setShowSide(true);
          }}
        ></i>
      </div>
      <section className="w-[260px] hidden lg:fixed lg:block">
        <LendSide
          setTheme={setTheme}
          theme={theme}
          connected={connected}
          addy={addy}
          setConnectWallet={setConnectWallet}
          connectWallet={connectWallet}
          setBreakdown={setBreakdown}
          showDetails={showDetails}
          setShowDetails={setShowDetails}
          setConnected={setConnected}
          benqiBalance={formatter(benqiBalance, "token")}
        />
      </section>

      <section className="w-[100%]">
        <Outlet />
      </section>

      <Modal isOpen={showSide} onClose={showSide} className="lg:hidden">
        <ModalContent className="flex flex-col items-center w-[100%] min-h-[100vh] z-50 fixed lg;hidden">
          <div
            className="bg-white dark:bg-[#334758] bg-opacity-40 dark:bg-opacity-90 w-[100%] min-h-[100vh] absolute z-40"
            onClick={() => {
              setShowSide(false);
            }}
          ></div>

          <ModalBody className="flex z-50 flex-col items-center justify-start pt-[150px] min-h-[100vh] w-[100%]">
            <Drawer
              placement="left"
              isOpen={showSide}
              onClose={onClose}
              className="fixed left-0 lg:hidden flex-col items-center justify-center z-50 bg-red-500 pb-[20px] min-h-[100vh]"
            >
              <section className="flex flex-col items-center w-[80%] h-[100%] fixed top-0 min-h-[100vh]">
                <LendSide
                  setTheme={setTheme}
                  theme={theme}
                  connected={connected}
                  addy={addy}
                  setShowSide={setShowSide}
                  setConnectWallet={setConnectWallet}
                  connectWallet={connectWallet}
                  setBreakdown={setBreakdown}
                  showDetails={showDetails}
                  setShowDetails={setShowDetails}
                  setConnected={setConnected}
                />
              </section>
            </Drawer>
          </ModalBody>
        </ModalContent>
      </Modal>

      {connected && (
        <Modal isOpen={breakdown} onClose={breakdown} className="">
          <ModalContent className="flex flex-col items-center w-[100%] min-h-[100vh] z-50 fixed">
            <div
              className="bg-white dark:bg-[#334758] bg-opacity-90 dark:bg-opacity-90 w-[100%] min-h-[100vh] absolute z-40"
              onClick={() => {
                setBreakdown(false);
              }}
            ></div>

            <ModalBody className="flex z-50 flex-col items-center justify-start pt-[150px] min-h-[100vh] w-[100%]">
              <section
                className="dark:bg-[#001016] bg-white border-solid border-[2px] border-[#00b3ed] flex flex-col items-center 
            lg:w-[360px] w-[100%] rounded-[.75rem] p-[15px]"
              >
                <div className="flex flex-row items-end justify-end w-[100%]">
                  <FontAwesomeIcon
                    icon={faX}
                    className="dark:text-white text-black text-[18px] cursor-pointer"
                    onClick={() => {
                      setBreakdown(false);
                    }}
                  />
                </div>
                <h1 className="text-[1.688rem] font-[300] text-black dark:text-white">
                  Your QI Breakdown
                </h1>

                <section
                  className="lg:flex flex-row items-center justify-between rounded-[0.1875rem]
        dark:bg-[#002430] w-[100%] px-[1.25rem] my-[20px] bg-[#d7edfd] py-[1.375rem]"
                >
                  <img
                    src={
                      theme === "dark" ? "./images/xd.svg" : "./images/x.svg"
                    }
                    className="w-[36px]"
                  />

                  <h1 className="dark:text-white text-black text-[1.688rem]">
                    {formatter(benqiBalance, "token")}
                  </h1>
                </section>

                <section
                  className="lg:flex flex-col items-center justify-between bg-[#d7edfd] rounded-[0.1875rem]
        dark:bg-[#002430] w-[100%] px-[1.25rem] my-[20px] bg-[#d7edfd] py-[1.375rem] mt-[10px] text-[14px]"
                >
                  <div className="flex flex-row items-center justify-between w-[100%] dark:text-[#d2d2d2] ">
                    <h1>Balance</h1>

                    <h1 className="dark:text-white text-black ">
                      {formatter(benqiBalance, "token")}
                    </h1>
                  </div>
                  <div className="flex flex-row items-center justify-between w-[100%] dark:text-[#d2d2d2] pt-[10px]">
                    <h1>Unclaimed</h1>

                    <h1 className="dark:text-white text-black ">
                      {formatter(0.2 * benqiBalance, "token")}
                      <button
                        className={`ml-[10px] border-solid border-[1px] 
        border-[${isLoading ? "#434f5b" : "#ffffff"}] text-[${
                          isLoading ? "#434f5b" : "#ffffff"
                        }] rounded-[0.1875rem] px-[0.25rem]`}
                        onClick={() => action()}
                        disabled={false}
                      >
                        Claim
                      </button>
                    </h1>
                  </div>
                </section>
              </section>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}

      {connected && (
        <Modal isOpen={showDetails} onClose={showDetails} className="">
          <ModalContent className="flex flex-col items-center w-[100%] min-h-[100vh] z-50 fixed">
            <div
              className="bg-white dark:bg-[#334758] bg-opacity-90 dark:bg-opacity-90 w-[100%] min-h-[100vh] absolute z-40"
              onClick={() => {
                setShowDetails(false);
              }}
            ></div>

            <ModalBody className="flex z-50 flex-col items-center bg-black justify-start pt-[150px] min-h-[100vh] w-[100%]">
              <section
                className="dark:bg-[#001016] bg-white border-solid border-[2px] border-[#00b3ed] flex flex-col items-center 
            lg:w-[500px] w-[100%] rounded-[.75rem] p-[15px]"
              >
                <div className="flex flex-row items-end justify-end w-[100%]">
                  <FontAwesomeIcon
                    icon={faX}
                    className="dark:text-white text-black text-[18px] cursor-pointer"
                    onClick={() => {
                      setShowDetails(false);
                    }}
                  />
                </div>
                <h1 className="text-[1.688rem] font-[300] text-black dark:text-white">
                  Account
                </h1>

                <section
                  className="flex-col items-center justify-between rounded-[0.1875rem]
        dark:bg-transparent dark:text-white w-[100%] px-[2.5rem] my-[20px] bg-[#d7edfd] py-[1.375rem]"
                >
                  <div className="flex flex-col items-start w-[100%] pt-[10px]">
                    <h1 className="text-[14px] text-[#b9b9b9]">
                      Connected wallet
                    </h1>
                    <h1 className="text-[22px]">{truncateLarge(addy)}</h1>
                  </div>
                  <div className="flex flex-row items-center w-[100%] text-[#00b3ed] text-[0.875rem] dark:text-[#91e4ff]">
                    <a href="" className="flex flex-row items-center">
                      View on explorer{" "}
                      <img
                        src={
                          theme === "dark"
                            ? "./images/sh.svg"
                            : "./images/sh2.svg"
                        }
                        className="w-[12px] ml-[5px]"
                      />
                    </a>
                    <button
                      className="flex flex-row items-center pl-[20px]"
                      onClick={() => {
                        disconnect();
                      }}
                    >
                      Disconnect{" "}
                      <img
                        src={
                          theme === "dark"
                            ? "./images/di.svg"
                            : "./images/di2.svg"
                        }
                        className="w-[12px] ml-[5px]"
                      />
                    </button>
                  </div>

                  <div className="flex flex-col items-start w-[100%] pt-[10px]">
                    <h1 className="text-[14px] text-[#b9b9b9]">Network</h1>
                    <h1 className="text-[22px]">Mainnet</h1>
                  </div>

                  <div className="flex flex-col items-start w-[100%] pt-[10px]">
                    <h1 className="text-[14px] text-[#b9b9b9]">
                      Recent transactions
                    </h1>
                    <h1 className="text-[22px]">No transactions</h1>
                  </div>

                  <div className="flex flex-col items-start w-[100%] pt-[10px]">
                    <h1 className="text-[14px] text-[#b9b9b9]">Liquidations</h1>
                    <h1 className="text-[22px]">No liquidations</h1>
                  </div>
                </section>
              </section>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}

      <Connect
        setConnectWallet={setConnectWallet}
        connectWallet={connectWallet}
        onClose={onClose}
      />
    </body>
  );
};

export default LendLayout;
