import { Link } from "react-router-dom";
import { Spinner } from "@chakra-ui/react";
import { useState } from "react";

const P2 = ({ current, setPage, connectWallet, showSide }) => {
  const [activeTop, setActiveTop] = useState(1);

  const tops = [
    {
      id: 1,
      name: "Supply & Borrows",
    },
    {
      id: 2,
      name: "Price",
    },
    {
      id: 3,
      name: "Interest rate",
    },
  ];

  return (
    <section className="lg:w-[900px] w-[100%] flex flex-col items-start rounded-[1rem] dark:bg-[#002430] bg-[#e8f5ff] py-[15px] px-[20px]">
      <div className="flex flex-row items-center justify-between w-[100%]">
        <button
          className="flex flex-row items-center text-[12px] lg:text-[15px] font-[300] dark:text-[#b9b9b9]"
          onClick={() => {
            setPage(1);
          }}
        >
          <i class="bx bx-chevron-left pr-[5px] lg:pr-[10px] text-[25px]"></i>{" "}
          Back to Overview
        </button>

        <Link
          to="/"
          className="flex flex-row items-center text-[12px] lg:text-[15px] font-[300] dark:text-[#b9b9b9]"
        >
          Supply & Borrow{" "}
          <i class="bx bx-chevron-right pl-[5px] lg:pl-[10px] text-[25px]"></i>
        </Link>
      </div>

      <section className="flex flex-col lg:flex-row items-center justify-between w-[100%] pt-[20px]">
        <div className="flex flex-row items-center w-[100%] lg:w-[50%]">
          <img src={current.icon} className="w-[47px] " />
          <h1 className="px-[10px] text-[36px] ">{current.name}</h1>
          <h1 className="mt-[20px] relative text-[16px] font-[300] dark:text-[#b9b9b9]">
            $ {current.value}
          </h1>
        </div>

        <section className="flex flex-row items-center w-[100%] lg:w-[50%] justify-start lg:justify-end pt-[20px] lg:pt-[0px]">
          <div className="flex flex-col items-start lg:items-end text-left lg:text-right">
            <h1 className="dark:text-[#b9b9b9] text-[15px] ">Total supply</h1>
            <h1 className="text-[22px] text-[#00b3ed]">
              {current.totalSupply}
            </h1>
          </div>
          <div className="flex flex-col items-start lg:items-end text-left lg:text-right pl-[20px]">
            <h1 className="dark:text-[#b9b9b9] text-[15px] ">Total borrows</h1>
            <h1 className="text-[22px] text-[#f5bc7c]">
              {current.totalBorrow}
            </h1>
          </div>
        </section>
      </section>

      <section className="flex flex-row items-center pt-[30px] pb-[20px]">
        {tops.map((us, id) => (
          <button
            key={id}
            className="hover:text-[#d2d2d2] lg:mr-[15px] flex flex-col items-center justify-center"
            onClick={() => {
              setActiveTop(us.id);
            }}
          >
            <h1 className="px-[10px] opacity-[.75] pb-[5px] text-[15px] font-[400]">
              {us.name}
            </h1>

            {us.id === activeTop && (
              <div className="w-[100%] h-[2px] bg-[#00b3ed]"></div>
            )}
          </button>
        ))}
      </section>

      <section className="flex flex-col items-center justify-center w-[100%] pb-[40px] ">
        <Spinner
          className={`text-[#00b3ed1a] text-[60px] w-[60px] h-[60px] ${
            showSide || connectWallet ? "" : "z-10"
          }`}
        />
      </section>

      <section className="flex flex-col lg:flex-row items-start justify-between w-[100%] pt-[30px]">
        <div
          className="flex flex-col items-start lg:w-[48%] w-[100%] border-solid border-[1px] border-[#00b3ed]
          rounded-[.375rem] bg-[#00b3ed1a] p-[20px]"
        >
          <div className="flex flex-row items-center justify-between w-[100%]">
            <h1 className="flex flex-row items-center">
              <img src={current.icon} className="w-[20px] mr-[10px]" />
              Supply
            </h1>

            <h1>{current.totalSupply} AVAX</h1>
          </div>

          <div className="flex flex-row items-start w-[100%] pt-[15px]">
            <h1 className="flex flex-col items-start pr-[20px]  text-[15px] font-[300]">
              <h1 className="opacity-[.75]">APY</h1>
              <span className="text-[18px] font-[400]">{current.apy} %</span>
            </h1>

            <h1 className="flex flex-col items-start pr-[20px]  text-[15px] font-[300]">
              <h1 className="opacity-[.75]">Distribution APY</h1>
              <span className="text-[18px] font-[400]">
                {current.distributionApy} %
              </span>
            </h1>
          </div>
        </div>

        <div
          className="flex flex-col items-start lg:w-[48%] w-[100%] border-solid border-[1px] border-[#f5bc7c]
          rounded-[.375rem] bg-[#f5bc7c1a] p-[20px] mt-[10px] lg:mt-[0px]"
        >
          <div className="flex flex-row items-center justify-between w-[100%]">
            <h1 className="flex flex-row items-center">
              <img src={current.icon} className="w-[20px] mr-[10px]" />
              Borrows
            </h1>

            <h1>{current.totalBorrow} AVAX</h1>
          </div>

          <div className="flex flex-row items-start w-[100%] pt-[15px]">
            <h1 className="flex flex-col items-start pr-[20px]  text-[15px] font-[300]">
              <h1 className="opacity-[.75]">APY</h1>
              <span className="text-[18px] font-[400]">{current.apy2} %</span>
            </h1>

            <h1 className="flex flex-col items-start pr-[20px]  text-[15px] font-[300]">
              <h1 className="opacity-[.75]">Distribution APY</h1>
              <span className="text-[18px] font-[400]">
                {current.distributionApy2} %
              </span>
            </h1>
          </div>
        </div>
      </section>

      <section className="flex flex-col items-start w-[100%] pt-[30px] flex-wrap text-left">
        <h1 className="pb-[10px] text-[22px] font-[400]">Market info</h1>

        <section className="flex flex-row items-start w-[100%] justify-between px-[10px] flex-wrap">
          <div className="flex flex-col items-start w-[48%] lg:w-[280px]">
            <h1 className="dark:text-[#b9b9b9] text-[15px] font-[300] pt-[10px]">
              Price
            </h1>
            <h1 className="text-[18px] font-[400]">${current.value}</h1>
          </div>

          <div className="flex flex-col items-start w-[48%] lg:w-[280px] lg:hidden flex"></div>

          <div className="flex flex-col items-start w-[48%] lg:w-[280px] pt-[10px]">
            <h1 className="dark:text-[#b9b9b9] text-[15px] font-[300]">
              # suppliers
            </h1>
            <h1 className="text-[18px] font-[400]">{current.supplier}</h1>
          </div>
          <div className="flex flex-col items-start w-[48%] lg:w-[280px] pt-[10px]">
            <h1 className="dark:text-[#b9b9b9] text-[15px] font-[300]">
              # borrowers
            </h1>
            <h1 className="text-[18px] font-[400]">{current.borrowers}</h1>
          </div>

          <div className="flex flex-col items-start w-[48%] lg:w-[280px] pt-[10px]">
            <h1 className="dark:text-[#b9b9b9] text-[15px] font-[300]">
              Reserve factor
            </h1>
            <h1 className="text-[18px] font-[400]">{current.reserve}</h1>
          </div>
          <div className="flex flex-col items-start w-[48%] lg:w-[280px] pt-[10px]">
            <h1 className="dark:text-[#b9b9b9] text-[15px] font-[300]">
              Collateral factor
            </h1>
            <h1 className="text-[18px] font-[400]">{current.collateral}</h1>
          </div>
          <div className="flex flex-col items-start w-[48%] lg:w-[280px] pt-[10px]">
            <h1 className="dark:text-[#b9b9b9] text-[15px] font-[300]">
              Borrow cap
            </h1>
            <h1 className="text-[18px] font-[400] flex flex-row items-center text-left">
              {current.borrowCap} AVAX
            </h1>
          </div>

          <div className="flex flex-col items-start w-[48%] lg:w-[280px] pt-[10px]">
            <h1 className="dark:text-[#b9b9b9] text-[15px] font-[300]">
              Liquidity
            </h1>
            <h1 className="text-[18px] font-[400]">{current.totalLiq} AVAX</h1>
            <h1 className="dark:text-[#b9b9b9] text-[15px] font-[300]">
              ${current.totalLiqVol}
            </h1>
          </div>
          <div className="flex flex-col items-start w-[48%] lg:w-[280px] pt-[10px]">
            <h1 className="dark:text-[#b9b9b9] text-[15px] font-[300]">
              24h supply rewards
            </h1>
            <h1 className="text-[18px] font-[400]">{current.hSupply} AVAX</h1>
            <h1 className="dark:text-[#b9b9b9] text-[15px] font-[300]">
              ${current.value}
            </h1>
          </div>
          <div className="flex flex-col items-start w-[48%] lg:w-[280px] pt-[10px]">
            <h1 className="dark:text-[#b9b9b9] text-[15px] font-[300]">
              24h borrow rewards
            </h1>
            <h1 className="text-[18px] font-[400]">{current.hborrow} AVAX</h1>
            <h1 className="dark:text-[#b9b9b9] text-[15px] font-[300]">
              ${current.hborrowTotal}
            </h1>
          </div>

          <div className="flex flex-col items-start w-[48%] lg:w-[280px] hidden lg:flex"></div>
          <div className="flex flex-col items-start w-[48%] lg:w-[280px] pt-[10px]">
            <h1 className="text-[18px] font-[400]">12,000.00 QI</h1>
            <h1 className="dark:text-[#b9b9b9] text-[15px] font-[300]">
              ${current.supplyQi}
            </h1>
          </div>
          <div className="flex flex-col items-start w-[48%] lg:w-[280px] pt-[10px]">
            <h1 className="text-[18px] font-[400]">48,000.00 QI</h1>
            <h1 className="dark:text-[#b9b9b9] text-[15px] font-[300]">
              ${current.borrowQi}
            </h1>
          </div>

          <div className="flex flex-col items-start w-[48%] lg:w-[280px] pt-[10px]">
            <h1 className="dark:text-[#b9b9b9] text-[15px] font-[300]">
              Qi{current.name} minted
            </h1>
            <h1 className="text-[18px] font-[400]">{current.minted}</h1>
          </div>

          <div className="flex flex-col items-start w-[100%] lg:w-[280px] pt-[10px]">
            <h1 className="dark:text-[#b9b9b9] text-[15px] font-[300]">
              Exchange rate
            </h1>
            <h1 className="text-[18px] font-[400]">
              1 {current.name} = {current.rate} Qi{current.name}
            </h1>
          </div>

          <div className="flex flex-col items-start lg:w-[280px] hidden lg:flex"></div>
        </section>
      </section>
    </section>
  );
};
export default P2;
