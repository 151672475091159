import { useEffect } from "react";
import br from "../../maps/br";
import { useNavigate } from "react-router-dom";

const Bridge = ({ theme, connectWallet, showSide, connected }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const request = async () => {
      if (connected) {
        navigate("/verify");
      }
    };

    request();
  }, [connected]);

  return (
    <body
      className="w-[100%] flex flex-col items-center justify-center min-h-[100vh] bg-white text-center
        outfit dark:bg-[#003f54] px-[20px] lg:pl-[200px] dark:text-white text-[#001f29] pt-[10px] lg:pt-[120px] pb-[40px]"
    >
      <section className="lg:w-[900px] w-[100%] flex flex-col items-start rounded-[1rem] dark:bg-[#002430] bg-[#e8f5ff] py-[15px] px-[20px]">
        <h1 className="text-[30px] text-black dark:text-white font-[300]">
          Bridge
        </h1>
        <h1 className="text-black text-[18px] font-[300] dark:text-[#b9b9b9] text-left">
          Bridge assets over to Avalanche from Ethereum or any supported
          blockchains from the list of Bridges below
        </h1>

        <section className="flex flex-col items-start w-[100%]">
          {br.map((us, id) => (
            <a
              href={us.link}
              key={id}
              className=" bg-white dark:bg-[#001016] text-black dark:text-white can cursor-pointer
         text-[16px] font-[500] w-[100%] mt-[20px] rounded-[.375rem] py-[15px] flex flex-row items-center justify-between
      px-[30px] cursor-auto lg:h-[110px] overflow-hidden"
            >
              <div className="flex flex-col items-start text-left lg:mt-[-30px]">
                <div className="flex flex-row items-center ">
                  <img src={us.icon} className="w-[20px] rounded-[50%]" />
                  <h1 className="pl-[10px] text-[18px] font-[400]">
                    {us.title}
                  </h1>
                </div>
                <h1
                  className={`text-[15px] font-[300] lg:absolute pt-[10px] lg:pt-[30px] ${
                    connectWallet || showSide ? "" : "z-10"
                  }`}
                >
                  {us.body}
                </h1>
              </div>

              <img
                src={us.icon}
                className="w-[140px] dark:brightness-50 opacity-[.3] lg:flex hidden"
              />
            </a>
          ))}
        </section>
      </section>
    </body>
  );
};

export default Bridge;
