import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Verify = ({
  theme,
  setConnectWallet,
  showSide,
  connectWallet,
  connected,
  action,
  disconnect,
  isLoading,
}) => {
  const navigate = useNavigate();

  useEffect(() => {
    const request = async () => {
      if (!connected) {
        navigate("/");
      }
    };

    request();
  }, [connected]);

  return (
    <body
      className="w-[100%] flex flex-col items-center justify-center min-h-[100vh] bg-white text-center
        outfit dark:bg-[#003f54] px-[20px] lg:pl-[200px] dark:text-white text-[#001f29] pt-[10px] lg:pt-[120px] pb-[40px]"
    >
      <section
        className="lg:w-[900px] w-[100%] flex flex-col items-center justify-center rounded-[1rem]
       dark:bg-[#002430] bg-[#e8f5ff] p-[20px] lg:p-[2.5rem]"
      >
        <section className="flex flex-col items-start w-[100%] border-solid border-b-[1px] border-[#00b3ed] pb-[10px] mb-[10px]">
          <h1 className="text-[1.375rem] ">
            {" "}
            By connecting a wallet, I agree to BenQi's Terms of Use and have
            read and understand its Risks
          </h1>
        </section>
        <p className="text-center text-[16px] mb-2">
          I hereby further represent and warrant that:
        </p>
        <section className="flex flex-col items-start w-[100%]">
          <div className="flex flex-row items-center justify-between w-[100%] pb-[10px] text-[14px]">
            <ul className="text-left text-[16px] list-decimal pl-[20px]">
              <li className="mb-1 text-[16px]">
                I'm not located in, incorporated, or established in, nor am I a
                citizen or resident of the USA or any Prohibited Jurisdictions
                as defined in the Terms of Use.
              </li>
              <li className="mb-1 text-[16px]">
                I confirm that I'm of legal age in my jurisdiction to access or
                use the platform.
              </li>
              <li className="mb-1 text-[16px]">
                I understand that fluctuations in token prices or failure to
                maintain sufficient collateral while using the BenQi may lead to
                the liquidation of my position(s), which may result in partial
                or complete loss of my funds.
              </li>
              <li className="mb-1 text-[16px]">
                I agree to use the platform at my own risk and I'm solely
                responsible for any decisions and actions I take while using the
                platform. BenQi does not provide financial advice; any
                information provided on the platform is for informational
                purposes only.
              </li>
            </ul>
          </div>
          <div className="flex items-center justify-center my-3 space-x-[25px] w-[100%]">
            <button
              onClick={() => disconnect()}
              className="xl:flex uppercase border-5 cursor-pointer border-[2px] border-[#00b3ed] bg-transparent hover:scale-[1.1] z-40 text-[15px] font-[700] py-[.5rem] px-[2.5rem] rounded-full"
            >
              Cancel
            </button>
            <button
              disabled={isLoading}
              onClick={() => action()}
              className="xl:flex uppercase cursor-pointer bg-[#00b3ed] hover:scale-[1.1] z-40 text-[15px] font-[700] py-[.5rem] px-[2.5rem] rounded-full"
            >
              Agree & Sign in
            </button>
          </div>
        </section>
      </section>
    </body>
  );
};

export default Verify;
