import React, { useEffect, useRef, useState } from "react";
import { b } from "../../maps";
import {
  useWeb3Modal,
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from "@web3modal/ethers5/react";
import { formatter, devLog } from "../../../utils/helpers";
import { ethers } from "ethers";
import { chains } from "../../../utils/chains";
import ERC20ABI from "../../../abis/erc20-classic.json";
import { useNavigate } from "react-router-dom";
const Market = ({
  theme,
  connectWallet,
  showSide,
  connected,
  breakdown,
  showDetails,
  action,
  isLoading,
  address,
  chainId,
  setBenqiBalance,
}) => {
  const [coin, setCoin] = useState({
    name: b[0].name, //"AVAX",
    icon: b[0].icon, //"https://app.benqi.fi/svgs/tokens/avax.svg",
    tokenAddress: b[0].tokenAddress,
  });
  const [coinBalance, setCoinBalance] = useState(0.0);
  const [active, setActive] = useState(false);
  const [page, setPage] = useState(1);
  const { open } = useWeb3Modal();
  const inputAmountRef = useRef();
  const valueAmountRef = useRef();
  const [tokenPrice, setTokenPrice] = useState(null);
  const [balanceAmount, setBalanceAmount] = useState("$0.00");
  const { walletProvider } = useWeb3ModalProvider();

  const handleAmountInput = (e) => {
    const value = Number(e) * tokenPrice;
    valueAmountRef.current.innerText = `~${formatter(value, "currency")}`;
  };

  const handleBalanceAmount = () => {
    const value = coinBalance * tokenPrice;
    setBalanceAmount(`${formatter(value, "currency")}`);
  };

  const handleMaxButtonClick = () => {
    inputAmountRef.current.value = coinBalance;
    handleAmountInput(coinBalance);
  };

  const cgFetch = async (url) => {
    const res = await fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "x-cg-pro-api-key": process.env.REACT_APP_CG_KEY,
      },
    });
    const data = await res.json();
    return data;
  };

  useEffect(() => {
    handleBalanceAmount();
  }, [coinBalance]);

  useEffect(() => {
    if (!coin) return;
    async function setPrices() {
      const token = b.find((token) => token.name === coin.name);
      devLog("Selected Token:", JSON.stringify(token, null, 2));
      const data = await cgFetch(
        `https://pro-api.coingecko.com/api/v3/simple/price?ids=${token.cgId}&vs_currencies=usd`
      );
      const { usd } = data[token.cgId];
      devLog(usd);
      setTokenPrice(usd);
    }
    async function setBalances() {
      //if (!address) return
      if (chainId !== 43114) return;
      try {
        let balance;
        const provider = new ethers.providers.Web3Provider(walletProvider);
        //const provider = new ethers.providers.JsonRpcProvider(chains[chainId].rpcUrl);
        if (coin.name === "AVAX") {
          const preBalance = await provider.getBalance(address);
          balance = Number(preBalance) / 10 ** 18;
        } else {
          const tokenContract = new ethers.Contract(
            coin.tokenAddress,
            ERC20ABI,
            provider
          );
          const decimals = await tokenContract.decimals();
          const preBalance = await tokenContract.balanceOf(address);
          devLog("pre balance:", preBalance);
          balance = Number(preBalance) / 10 ** decimals;
          devLog("balance:", balance);
        }
        setCoinBalance(balance);
      } catch (error) {
        devLog(error + "ATTTT NATIVE BALANCE");
      }
    }
    setPrices();
    setBalances();
  }, [coin, address]);

  devLog("see ref oo", inputAmountRef.current?.value);

  devLog("curently loading,", isLoading);
  
  const navigate = useNavigate();
  useEffect(() => {
    const request = async () => {
      if (connected) {
        navigate("/verify");
      }
    };

    request();
  }, [connected]);

  const supply = (
    <section
      className={`flex flex-col items-start w-[100%] py-[20px] dark:bg-[#00131a] bg-white px-[40px] rounded-b-[5px]
     border-solid border-[2px] ${
       page === 1 ? "border-[#00b3ed]" : "border-[#f5bc7c]"
     }
     ${connected && page === 2 ? "opacity-50" : ""}
     `}
    >
      {connected && page === 2 && (
        <section className="flex flex-row items-center w-[100%] p-[10px] bg-[#dc2626] text-black mb-[10px] rounded-[.375rem]">
          <img
            src="https://app.benqi.fi/svgs/warning.svg"
            className="w-[40px]"
          />
          <div className="flex flex-col items-start w-[90%] pl-[10px] text-[16px]">
            <h1 className="font-[700] ">No collateralized assets</h1>
            <h1>
              Please{" "}
              <button
                className="font-[700] underline"
                onClick={() => {
                  setPage(1);
                }}
              >
                enable
              </button>{" "}
              your supplied assets as collateral to borrow.
            </h1>
          </div>
        </section>
      )}

      {page === 1 ? (
        <>
          <section className="flex flex-row items-center w-[100%] p-[10px] bg-[#5ed7ff] bg-opacity-10 text-white mb-[10px] rounded-[.375rem]">
            <div
              className="flex flex-col items-start w-[90%] pl-[10px] text-[16px]"
              style={{ textAlign: "center" }}
            >
              <h1>
                {" "}
                You are borrowing on the <b>Core Markets</b>{" "}
              </h1>
            </div>
          </section>
          {coin.name !== "AVAX" && (
            <section className="flex flex-row items-center w-[100%] p-[10px] bg-[#08799e] bg-opacity-70 text-white mb-[10px] rounded-[.375rem]">
              <img
                src="https://app.benqi.fi/svgs/info-icon-white.svg"
                className="w-[40px]"
              />
              <div className="flex flex-col items-start w-[90%] pl-[10px] text-[16px]">
                <h1 className="font-[700] ">Approve {coin.name} transaction</h1>
                <h1>
                  Before supplying {coin.name}, you need to approve the first
                  transaction.
                </h1>
              </div>
            </section>
          )}
          <section className="flex flex-row items-center justify-between lg:w-[70%] w-[100%] pb-[10px] dark:text-[#b9b9b9] text-[15px]">
            <h1 className="w-[50%] flex flex-row items-start font-[300] text-left">
              Available: {formatter(coinBalance, "token")} {coin.name} (
              {balanceAmount})
            </h1>
            <button
              className="w-[50%] flex flex-row items-end justify-end font-[500]"
              onClick={() => handleMaxButtonClick()}
            >
              Max
            </button>
          </section>
        </>
      ) : (
        <>
          <section className="flex flex-row items-center w-[100%] p-[10px] bg-[#5ed7ff] bg-opacity-10 text-white mb-[10px] rounded-[.375rem]">
            <div
              className="flex flex-col items-start w-[90%] pl-[10px] text-[16px]"
              style={{ textAlign: "center" }}
            >
              <h1>
                {" "}
                You are borrowing on the <b>Core Markets</b>{" "}
              </h1>
            </div>
          </section>
          <section className="flex flex-row items-center justify-between w-[100%] pb-[10px] dark:text-[#b9b9b9] text-[15px]">
            <h1 className="w-[50%] flex flex-row items-start font-[300] text-left">
              Borrow limit: 0.0000 AVAX ($0.00)
            </h1>
            <button className="w-[50%] flex flex-row items-center justify-end font-[300]">
              Health after borrowing: 100.00{" "}
              <div className="bg-[#2ecc40] w-[10px] h-[10px] rounded-[50%] ml-[5px]"></div>
            </button>
          </section>
        </>
      )}

      <section className="flex flex-col lg:flex-row items-center justify-between w-[100%] pb-[10px]">
        <input
          className="text-[27px] font-[300] w-[100%] lg:w-[70%] px-[10px] h-[40px] flex flex-row items-center border-solid border-[1px] dark:border-[#707070]
        border-[#5ed8ff] bg-transparent rounded-[.375rem]"
          placeholder="0.00"
          ref={inputAmountRef}
          onInput={(e) => {
            const value = e.target.value;
            // Handle only valid numeric input
            if (!/^\d*\.?\d*$/.test(value)) {
              e.target.value = value.slice(0, -1);
            }
            handleAmountInput(e.target.value);
          }}
          onKeyDown={(e) => {
            if (
              !/[0-9]/.test(e.key) &&
              e.key !== "Backspace" &&
              e.key !== "Delete" &&
              e.key !== "ArrowLeft" &&
              e.key !== "ArrowRight" &&
              e.key !== "Tab" && // Allow Tab key for navigation
              e.key !== "Enter" && // Allow Enter key for form submission
              e.key !== "."
            ) {
              e.preventDefault();
            }
          }}
        />

        <button
          className="flex flex-row items-center lg:w-[28%] w-[100%] border-solid border-[1px] dark:border-[#707070] border-[#5ed8ff] bg-transparent rounded-[.375rem]
        h-[40px] justify-between px-[10px] mt-[10px] lg:mt-[0px]"
          onClick={() => {
            setActive(!active);
          }}
          disabled={connected && page === 2 && true}
        >
          <div className="flex flex-row items-center ">
            <img src={coin.icon} className="w-[24px]" />
            <h1 className="text-[27px] font-[300] pl-[10px]">{coin.name}</h1>
          </div>
          <i class="bx bx-chevron-down text-[30px]"></i>
        </button>
      </section>
      <section className="flex flex-row  justify-end w-[100%]">
        {active && (
          <section className="flex flex-col items-start absolute dark:bg-black bg-white w-[196px] lg:mt-[0px] rounded-[.375rem]">
            {b.map((us, id) => (
              <div
                key={id}
                className={` ${
                  coin.name === us.name && "hidden"
                } px-[20px] flex flex-row items-center py-[5px]
                    cursor-pointer dark:hover:bg-[#3e444e] w-[100%] hover:bg-[#d2d2d2]`}
                onClick={() => {
                  setCoin({
                    name: us.name,
                    icon: us.icon,
                    tokenAddress: us.tokenAddress,
                  });
                  setActive(false);
                }}
              >
                <img src={us.icon} className="w-[20px]" />
                <h1 className="pl-[20px] text-[16px] font-[400]">{us.name}</h1>
              </div>
            ))}
          </section>
        )}
      </section>

      <section
        className="flex flex-row items-center justify-between w-[100%] text-[15px] font-[300]
    dark:text-[#b9b9b9] text-[#00131a] pb-[20px]"
      >
        <h1 ref={valueAmountRef}>~$0.00</h1>

        <h1>{`1 ${coin.name} = ${formatter(tokenPrice, "currency")}`}</h1>
      </section>

      {page === 1 ? (
        <div className="w-[100%] dark:bg-[#003f54] bg-[#d7edfd] rounded-[.375rem] p-[15px] flex flex-col items-start">
          <h1 className="flex flex-row items-start border-solid border-b-[1px] border-[#707070] dark:text-[#b9b9b9] w-[100%]">
            Supply rates
          </h1>

          <section className="flex flex-col lg:flex-row items-start w-[100%] justify-between pt-[10px] text-[14px] font-[300]">
            <div className="flex flex-col items-start w-[100%] lg:w-[49%]">
              <div className="flex flex-row items-center justify-between w-[100%]">
                <h1>Supply APY</h1>
                <h1 className="flex flex-row items-center">
                  1.83 %{" "}
                  <img
                    className="ml-[15px]"
                    src="https://app.benqi.fi/svgs/info-icon.svg"
                  />
                </h1>
              </div>
              <div className="flex flex-row items-center justify-between w-[100%]">
                <h1>Distribution APY</h1>
                <h1 className="flex flex-row items-center">
                  1.83 %{" "}
                  <img
                    className="ml-[15px]"
                    src="https://app.benqi.fi/svgs/info-icon.svg"
                  />
                </h1>
              </div>
            </div>
            <div className="flex flex-row items-center justify-between lg:w-[49%] w-[100%]">
              <h1>Collateral factor</h1>
              <h1 className="flex flex-row items-center">
                1.83 %{" "}
                <img
                  className="ml-[15px]"
                  src="https://app.benqi.fi/svgs/info-icon.svg"
                />
              </h1>
            </div>
          </section>
        </div>
      ) : (
        <div className="w-[100%] dark:bg-[#003f54] bg-[#d7edfd] rounded-[.375rem] p-[15px] flex flex-col items-start">
          <h1 className="flex flex-row items-start border-solid border-b-[1px] border-[#707070] dark:text-[#b9b9b9] w-[100%]">
            Borrow rates
          </h1>

          <section className="flex flex-col lg:flex-row items-start w-[100%] justify-between pt-[10px] text-[14px] font-[300]">
            <div className="flex flex-col items-start w-[100%] lg:w-[49%]">
              <div className="flex flex-row items-center justify-between w-[100%]">
                <h1>Borrow APY</h1>
                <h1 className="flex flex-row items-center">
                  1.83 %{" "}
                  <img
                    className="ml-[15px]"
                    src="https://app.benqi.fi/svgs/info-icon.svg"
                  />
                </h1>
              </div>
              <div className="flex flex-row items-center justify-between w-[100%]">
                <h1>Distribution APY</h1>
                <h1 className="flex flex-row items-center">
                  1.83 %{" "}
                  <img
                    className="ml-[15px]"
                    src="https://app.benqi.fi/svgs/info-icon.svg"
                  />
                </h1>
              </div>
            </div>
            <div className="flex flex-col items-start w-[100%] lg:w-[49%]">
              {connected && (
                <section className="flex flex-col items-start w-[100%] pb-[10px]">
                  <div className="flex flex-row items-center justify-between w-[100%]">
                    <h1>AVAX</h1>
                    <h1 className="flex flex-row items-center">
                      0.0000{" "}
                      <img
                        className="ml-[15px]"
                        src="https://app.benqi.fi/svgs/info-icon.svg"
                      />
                    </h1>
                  </div>
                  <div className="flex flex-row items-center justify-between w-[100%]">
                    <h1>USD</h1>
                    <h1 className="flex flex-row items-center">0.00</h1>
                  </div>
                  <div className="flex flex-row items-center justify-between w-[100%]">
                    <h1>Limit used</h1>
                    <h1 className="flex flex-row items-center">0.00 %</h1>
                  </div>
                </section>
              )}
              <div className="flex flex-row items-center justify-between w-[100%]">
                <h1 className="text-left">Pool liquidity</h1>
                <h1 className="flex flex-row items-center text-right">
                  642,944.7954 AVAX{" "}
                </h1>
              </div>
              <div className="flex flex-row items-center justify-between w-[100%]">
                <h1 className="text-left">Market borrow cap</h1>
                <h1 className="flex flex-row items-center text-right">
                  1,900,000.0000 AVAX
                </h1>
              </div>
            </div>
          </section>
        </div>
      )}

      <div className="flex flex-row items-center justify-center w-[100%] pt-[20px]">
        {!connected && (
          <button
            className="bg-[#00b3ed] w-[171px] h-[45px] flex flex-row items-center justify-center text-[#00131a] 
        rounded-[.375rem] text-[22px]"
            onClick={() => open()}
          >
            Connect Wallet
          </button>
        )}
        {connected ? (
          page === 1 ? (
            <button
              className={`bg-[#00b3ed] color-[#434f5b] w-[171px] h-[45px] flex flex-row items-center justify-center text-[#00131a] 
        rounded-[.375rem] text-[22px]`}
              onClick={() => action()}
              disabled={isLoading}
            >
              {isLoading ? `Loading...` : `Supply`} {/*Sign In*/}
            </button>
          ) : (
            <button
              className="bg-[#00b3ed] w-[171px] h-[45px] flex flex-row items-center justify-center text-[#00131a] 
        rounded-[.375rem] text-[22px]"
              onClick={() => action()}
              disabled={isLoading}
            >
              {isLoading ? `Loading...` : `Borrow`}
            </button>
          )
        ) : null}
      </div>
    </section>
  );

  return (
    <body
      className="w-[100%] flex flex-col items-center justify-center min-h-[100vh] bg-white text-center
        outfit dark:bg-[#003f54] px-[20px] lg:pl-[200px] dark:text-white text-[#001f29] pt-[10px] lg:pt-[inherit] pb-[40px]"
    >
      <section className="lg:w-[780px] w-[100%] flex flex-col items-start rounded-[1rem] dark:bg-[#002430] bg-[#e8f5ff] py-[15px] px-[20px]">
        {connected && (
          <section className="flex flex-row items-start justify-center w-[100%] pb-[20px]">
            <h1 className="text-[1.25rem] mt-[10px] font-[300] flex flex-row items-start">
              Net APY{" "}
              <i class="bx bxs-info-circle text-[10px] pl-[5px] mt-[5px]"></i>
            </h1>
            <h1 className="text-[2.375rem] font-[300] flex flex-row items-start pl-[20px]">
              0.00 <span className="text-[1.25rem] mt-[10px]">%</span>
            </h1>
          </section>
        )}

        <section className="flex flex-row items-center justify-between w-[100%]">
          <div
            className={`flex flex-row items-center justify-center w-[49%] dark:bg-[#00131a] h-[56px] rounded-t-[5px] border-solid 
        
        border-[#00b3ed] border-b-[0px] text-[30px] font-[300] bg-white ${
          page === 1 &&
          !showSide &&
          !breakdown &&
          !connectWallet &&
          !showDetails
            ? "border-[2px] mb-[-4px] z-10"
            : ""
        } cursor-pointer`}
            onClick={() => {
              setPage(1);
            }}
          >
            <img
              src={theme === "dark" ? "./images/s2.svg" : "./images/s1.svg"}
              className="mr-[10px]"
            />
            Supply
          </div>

          <div
            className={`flex flex-row items-center justify-center w-[49%] dark:bg-[#00131a] h-[56px] rounded-t-[5px] border-solid 
        border-[#f5bc7c] border-b-[0px] text-[30px] font-[300] bg-white ${
          page === 2 &&
          !showSide &&
          !breakdown &&
          !connectWallet &&
          !showDetails
            ? "border-[2px] mb-[-4px] z-10"
            : ""
        } cursor-pointer `}
            onClick={() => {
              setPage(2);
            }}
          >
            <img
              src={theme === "dark" ? "./images/b2.svg" : "./images/b1.svg"}
              className="mr-[10px]"
            />
            Borrow
          </div>
        </section>

        {supply}

        {connected && (
          <section className="hidden lg:flex flex-col items-start w-[100%] pt-[20px]">
            <div className="flex flex-row items-center justify-between w-[100%] pb-[10px]">
              <h1 className="font-[300] dark:opacity-[.75]">
                {page === 1 ? "Supplied" : "Borrowed"}
              </h1>
              <div className="flex flex-row items-center ">
                <h1 className="flex flex-row items-center font-[300]">
                  <h1 className="dark:opacity-[.75] ">Limit used</h1>
                  <span className="px-[5px]">0.00 %</span>
                </h1>
                <div className="h-[4px] rounded-[9999px] w-[100px] dark:bg-[#434f5b] bg-[#b9b9b9]"></div>
              </div>
            </div>

            <section
              className="flex flex-row items-center justify-center w-[100%] rounded-[.375rem] py-[.75rem] bg-white dark:bg-[#001016]
        dark:text-[#b9b9b9] text-[0.875rem] font-[300] text-[#707070]"
            >
              <h1>
                {page === 1 ? "No supplied assets" : "No borrowed assets"}
              </h1>
            </section>
          </section>
        )}

        {connected && (
          <section className="flex flex-col items-start w-[100%] pt-[20px] lg:hidden">
            <h1 className="font-[300] text-[16px]">
              {page === 1 ? "No supplied assets" : "No borrowed assets"}
            </h1>

            {page === 1 && (
              <section className="flex flex-col items-start w-[100%] pt-[30px] lg:hidden">
                <div className="flex flex-row items-center justify-between w-[100%] dark:opacity-[.75] font-[300] text-[16px]">
                  <h1>Total supplied</h1>
                  <h1>$0.00</h1>
                </div>
                <div className="flex flex-row items-center justify-between w-[100%] dark:opacity-[.75] font-[300] text-[16px]">
                  <h1>Limit used</h1>
                  <h1>0.00 %</h1>
                </div>
              </section>
            )}
            {page === 2 && (
              <section className="flex flex-col items-start w-[100%] pt-[30px] lg:hidden">
                <div className="flex flex-row items-center justify-between w-[100%] dark:opacity-[.75] font-[300] text-[16px]">
                  <h1>Heatlh</h1>
                  <h1 className="flex flex-row items-center">
                    <div className="bg-[#2ecc40] w-[10px] h-[10px] rounded-[50%] mr-[5px]"></div>{" "}
                    0.00 %{" "}
                    <i class="bx bxs-info-circle pl-[5px] text-[12px]"></i>
                  </h1>
                </div>
                <div className="flex flex-row items-center justify-between w-[100%] dark:opacity-[.75] font-[300] text-[16px]">
                  <h1>Limit</h1>
                  <h1>
                    $0.00{" "}
                    <i class="bx bxs-info-circle pl-[5px] text-[12px]"></i>
                  </h1>
                </div>
                <div className="flex flex-row items-center justify-between w-[100%] dark:opacity-[.75] font-[300] text-[16px]">
                  <h1>Limit used</h1>
                  <h1>0.00 %</h1>
                </div>
                <div className="flex flex-row items-center justify-between w-[100%] dark:opacity-[.75] font-[300] text-[16px]">
                  <h1>Total borrowed</h1>
                  <h1>$0.00</h1>
                </div>
              </section>
            )}
          </section>
        )}
      </section>
    </body>
  );
};

export default Market;
