
const ov = [
    {
        id: 1,
        name: "AVAX",
        icon: "https://app.benqi.fi/svgs/tokens/avax.svg",
        value: "27.08",
        apy: "3.97",
        supply: "1.45M",
        vol: "40.86M",
        apy2: "-2.46",
        borrow: "1.10M",
        borrowVol: "29.82M",
        liq: "351.04k",
        liqVol: "9.53M",
        distributionApy: "0.21",
        distributionApy2: "1.82",
        totalSupply: "1,358,940.04",
        totalBorrow: "717,959.72",
        supplier: "5",
        borrows: "5",
        reserve: "5",
        collateral: "5",
        borrowCap: "5",
        totalLiq: "5",
        totalLiqVol: "5",
        hSupply: "5",
        hborrow: "5",
        supplyQi: "5",
        supplyVal: "5",
        borrowQi: "5",
        totalb: "5",
        borrowQiTotal: "5",
        minted: "5",
        rate: "5"
    },
    {
        id: 2,
        name: "BTC.b",
        icon: "https://app.benqi.fi/svgs/tokens/btcb.svg",
        value: "63158",
        apy: "3.84",
        supply: "546.98",
        vol: "34.556M",
        apy2: "-0.90",
        borrow: "156.69",
        borrowVol: "9.90M",
        liq: "390.29",
        liqVol: "24.65M",
        distributionApy: "0.21",
        distributionApy2: "1.82",
        totalSupply: "1,358,940.04",
        totalBorrow: "717,959.72",
        supplier: "5",
        borrows: "5",
        reserve: "5",
        collateral: "5",
        borrowCap: "5",
        totalLiq: "5",
        totalLiqVol: "5",
        hSupply: "5",
        hborrow: "5",
        supplyQi: "5",
        supplyVal: "5",
        borrowQi: "5",
        totalb: "5",
        borrowQiTotal: "5",
        minted: "5",
        rate: "5"
    },
    {
        id: 3,
        name: "BUSD",
        icon: "https://app.benqi.fi/svgs/tokens/busd.svg",
        value: "1.00",
        apy: "4.28",
        supply: "32.03k",
        vol: "32.03k",
        apy2: "-8.43",
        borrow: "19.52k",
        borrowVol: "19.52k",
        liq: "12.51k",
        liqVol: "12.51k",
        distributionApy: "0.21",
        distributionApy2: "1.82",
        totalSupply: "1,358,940.04",
        totalBorrow: "717,959.72",
        supplier: "5",
        borrows: "5",
        reserve: "5",
        collateral: "5",
        borrowCap: "5",
        totalLiq: "5",
        totalLiqVol: "5",
        hSupply: "5",
        hborrow: "5",
        supplyQi: "5",
        supplyVal: "5",
        borrowQi: "5",
        totalb: "5",
        borrowQiTotal: "5",
        minted: "5",
        rate: "5"
    },
    {
        id: 4,
        name: "DAI.e",
        icon: "https://app.benqi.fi/svgs/tokens/dai.svg",
        value: "1.00",
        apy: "4.47",
        supply: "1.48M",
        vol: "1.48M",
        apy2: "-9.18",
        borrow: "868.45k",
        borrowVol: "868.10k",
        liq: "614.11k",
        liqVol: "613.86k",
        distributionApy: "0.21",
        distributionApy2: "1.82",
        totalSupply: "1,358,940.04",
        totalBorrow: "717,959.72",
        supplier: "5",
        borrows: "5",
        reserve: "5",
        collateral: "5",
        borrowCap: "5",
        totalLiq: "5",
        totalLiqVol: "5",
        hSupply: "5",
        hborrow: "5",
        supplyQi: "5",
        supplyVal: "5",
        borrowQi: "5",
        totalb: "5",
        borrowQiTotal: "5",
        minted: "5",
        rate: "5"
    },
    {
        id: 5,
        name: "LINK.e",
        icon: "https://app.benqi.fi/svgs/tokens/link.svg",
        value: "14.07",
        apy: "0.01",
        supply: "357.05k",
        vol: "5.02M",
        apy2: "-2.08",
        borrow: "2.25k",
        borrowVol: "31.64k",
        liq: "354.80k",
        liqVol: "4.99M",
        distributionApy: "0.21",
        distributionApy2: "1.82",
        totalSupply: "1,358,940.04",
        totalBorrow: "717,959.72",
        supplier: "5",
        borrows: "5",
        reserve: "5",
        collateral: "5",
        borrowCap: "5",
        totalLiq: "5",
        totalLiqVol: "5",
        hSupply: "5",
        hborrow: "5",
        supplyQi: "5",
        supplyVal: "5",
        borrowQi: "5",
        totalb: "5",
        borrowQiTotal: "5",
        minted: "5",
        rate: "5"
    },
    {
        id: 6,
        name: "QI",
        icon: "https://app.benqi.fi/svgs/tokens/benqi.svg",
        value: "0.01",
        apy: "0.00",
        supply: "68.79M",
        vol: "1.02M",
        apy2: "-2.02",
        borrow: "0.00",
        borrowVol: "0.00",
        liq: "68.79M",
        liqVol: "1.02M",
        distributionApy: "0.21",
        distributionApy2: "1.82",
        totalSupply: "1,358,940.04",
        totalBorrow: "717,959.72",
        supplier: "5",
        borrows: "5",
        reserve: "5",
        collateral: "5",
        borrowCap: "5",
        totalLiq: "5",
        totalLiqVol: "5",
        hSupply: "5",
        hborrow: "5",
        supplyQi: "5",
        supplyVal: "5",
        borrowQi: "5",
        totalb: "5",
        borrowQiTotal: "5",
        minted: "5",
        rate: "5"
    },
    {
        id: 7,
        name: "sAVAX",
        icon: "https://app.benqi.fi/svgs/tokens/savax.svg",
        value: "31.42",
        apy: "1.03",
        supply: "4.32M",
        vol: "135.78M",
        apy2: "1.27",
        borrow: "129.07k",
        borrowVol: "4.06M",
        liq: "4.19M",
        liqVol: "131.73M",
        distributionApy: "0.21",
        distributionApy2: "1.82",
        totalSupply: "1,358,940.04",
        totalBorrow: "717,959.72",
        supplier: "5",
        borrows: "5",
        reserve: "5",
        collateral: "5",
        borrowCap: "5",
        totalLiq: "5",
        totalLiqVol: "5",
        hSupply: "5",
        hborrow: "5",
        supplyQi: "5",
        supplyVal: "5",
        borrowQi: "5",
        totalb: "5",
        borrowQiTotal: "5",
        minted: "5",
        rate: "5"
      },
      {
        id: 8,
        name: "USDC",
        icon: "https://app.benqi.fi/svgs/tokens/usdc.svg",
        value: "1.00",
        apy: "9.80",
        supply: "35.63M",
        vol: "35.63M",
        apy2: "-5.82",
        borrow: "23.55M",
        borrowVol: "23.55M",
        liq: "12.08M",
        liqVol: "12.08M",
        distributionApy: "0.21",
        distributionApy2: "1.82",
        totalSupply: "1,358,940.04",
        totalBorrow: "717,959.72",
        supplier: "5",
        borrows: "5",
        reserve: "5",
        collateral: "5",
        borrowCap: "5",
        totalLiq: "5",
        totalLiqVol: "5",
        hSupply: "5",
        hborrow: "5",
        supplyQi: "5",
        supplyVal: "5",
        borrowQi: "5",
        totalb: "5",
        borrowQiTotal: "5",
        minted: "5",
        rate: "5"
      },
      {
        id: 9,
        name: "USDC.e",
        icon: "https://app.benqi.fi/svgs/tokens/usdc.svg",
        value: "1.00",
        apy: "46.95",
        supply: "535.69k",
        vol: "535.67k",
        apy2: "-63.27",
        borrow: "494.72k",
        borrowVol: "494.70k",
        liq: "40.97k",
        distributionApy: "0.21",
        distributionApy2: "1.82",
        totalSupply: "1,358,940.04",
        totalBorrow: "717,959.72",
        supplier: "5",
        borrows: "5",
        reserve: "5",
        collateral: "5",
        borrowCap: "5",
        totalLiq: "5",
        totalLiqVol: "5",
        hSupply: "5",
        hborrow: "5",
        supplyQi: "5",
        supplyVal: "5",
        borrowQi: "5",
        totalb: "5",
        borrowQiTotal: "5",
        minted: "5",
        rate: "5"
      }
]
const things = `
USDt
$1.00
12.03 %
24.35M USDt
$24.35M
-6.67 %
18.99M USDt
$19.00M
5.35M USDt
$5.35M

USDT.e
$1.00
6.93 %
1.37M USDT.e
$1.37M
-11.86 %
1.03M USDT.e
$1.03M
346.87k USDT.e
$346.98k

WBTC.e
$63,194.88
0.07 %
30.89 WBTC.e
$1.95M
-2.43 %
1.24 WBTC.e
$78.18k
29.66 WBTC.e
$1.87M

WETH.e
$3,395.04
2.13 %
1.44k WETH.e
$4.90M
-5.09 %
818.84 WETH.e
$2.78M
624.46 WETH.e
$2.12M`

export default ov